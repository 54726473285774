<template>
    <div class="widget-properties-editor">
        <h2>{{ name }}</h2>
        <!-- NAME -->
        <TextFieldEnh
            label="nome"
            name="inst-name"
            v-model:modelValue="widgetRef.instanceProperties.name"
            @update:modelValue="checkForUniqueName"
            :error="errorNameUniqueRef"
            :errorMessage="errorMessageNameUniqueRef"
        ></TextFieldEnh>
        <!-- LABEL -->
        <TextFieldEnh
            v-if="fields.includes('label')"
            label="testo label"
            name="inst-label"
            v-model:modelValue="widgetRef.instanceProperties.label"
        ></TextFieldEnh>
        <!-- PLACEHOLDER -->
        <TextFieldEnh
            v-if="fields.includes('placeholder')"
            label="testo placeholder"
            name="inst-placeholder"
            v-model:modelValue="widgetRef.instanceProperties.placeholder"
        ></TextFieldEnh>
        <!-- OPTION -->
        <TextFieldEnh
            v-if="fields.includes('option')"
            label="testo opzione"
            name="inst-option"
            v-model:modelValue="widgetRef.instanceProperties.option.label"
        ></TextFieldEnh>
        <!-- OPTIONS -->
        <OptionsEditor
            v-if="fields.includes('options')"
            label="Opzioni"
            name="inst-options"
            v-model:options="widgetRef.instanceProperties.options"
        ></OptionsEditor>
        <!-- MULTIPLE -->
        <div v-if="fields.includes('multiple')" class="flex flex-row gap-2 items-center">
            <input id="inst-multiple" type="checkbox" v-model="widgetRef.instanceProperties.multiple" />
            <label class="field-label" for="inst-multiple">multiple</label>
        </div>
        <!-- W -->
        <TextFieldEnh
            v-if="fields.includes('w')"
            label="larghezza"
            name="inst-w"
            v-model:modelValue="widgetRef.instanceProperties.w"
        ></TextFieldEnh>
        <!-- H -->
        <TextFieldEnh
            v-if="fields.includes('h')"
            label="altezza"
            name="inst-h"
            v-model:modelValue="widgetRef.instanceProperties.h"
        ></TextFieldEnh>
        <!-- MIN -->
        <TextFieldEnh
            v-if="fields.includes('min')"
            label="min"
            name="inst-min"
            v-model:modelValue="widgetRef.instanceProperties.min"
        ></TextFieldEnh>
        <!-- MAX -->
        <TextFieldEnh
            v-if="fields.includes('max')"
            label="max"
            name="inst-max"
            v-model:modelValue="widgetRef.instanceProperties.max"
        ></TextFieldEnh>
        <!-- STEP -->
        <TextFieldEnh
            v-if="fields.includes('step')"
            label="step"
            name="inst-step"
            v-model:modelValue="widgetRef.instanceProperties.step"
        ></TextFieldEnh>
        <!-- REQUIRED -->
        <h4>Stato</h4>
        <div class="flex flex-row gap-2 items-center mb-2">
            <input id="val-required" type="checkbox" v-model="widgetRef.validationProperties.required" />
            <label class="field-label" for="val-required">obbligatorio</label>
        </div>
        <div class="flex flex-row gap-2 items-center mb-2">
            <input id="val-disabled" type="checkbox" v-model="widgetRef.instanceProperties.disabled" />
            <label class="field-label" for="val-disabled">disabilitato</label>
        </div>
        <!-- RULES -->
        <h4>Regole</h4>
        <p>Aggiungi le regole di comportamento del componente</p>
        <!-- <RulesEditor
            label="regole"
            name="inst-rules"
            v-model:rules="widgetRef.instanceProperties.rules"
            :rules-conditions-already-set="{
                required: widgetRef.instanceProperties.required,
                disabled: widgetRef.instanceProperties.disabled,
            }"
        ></RulesEditor> -->
        <RulesEditorV2
            v-model:rules="widgetRef.instanceProperties.rules"
            :rules-already-set="{
                required: widgetRef.validationProperties.required,
                disabled: widgetRef.instanceProperties.disabled,
            }"
        ></RulesEditorV2>
    </div>
</template>

<script setup>
import { useVModel } from '@vueuse/core';

import { useFormEditorStore } from '../../../stores/formEditorStore';

import TextFieldEnh from '../../lib/TextFieldEnh.vue';
import OptionsEditor from './OptionsEditor.vue';
import RulesEditorV2 from './RulesEditorV2.vue';
import { ref } from 'vue';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    // nomi dele property di cui visualizzare l'editor
    fields: {
        type: Array,
        required: true,
    },
    // current widget
    widget: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['update:widget']);

const widgetRef = useVModel(props, 'widget', emit);

const errorNameUniqueRef = ref(false);
const errorMessageNameUniqueRef = ref('');

const formEditorStore = useFormEditorStore();
const checkForUniqueName = (event) => {
    if (!formEditorStore.isUniqueWidgetName(widgetRef.value.instanceProperties.id, event)) {
        errorNameUniqueRef.value = true;
        errorMessageNameUniqueRef.value = 'Il nome deve essere univoco.';
        return;
    }
    errorNameUniqueRef.value = false;
    errorMessageNameUniqueRef.value = '';
};
</script>

<style lang="postcss" scoped>
div.widget-properties-editor {
    @apply gap-2;
}
div.widget-properties-editor :deep(.field-label) {
    @apply font-bold;
}
/* div.widget-properties-editor :deep(.field-input) {
    @apply text-xs;
} */
div.widget-properties-editor :deep(.combobox-input) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.combobox-item) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.combobox-item .icon-small) {
    @apply h-3 w-3;
}
div.widget-properties-editor [type='checkbox'] + :deep(.field-label) {
    @apply mb-0;
}
div.widget-properties-editor :deep(.tags-input-root) {
    @apply h-auto p-[2px];
}
div.widget-properties-editor :deep(.tags-input-item-text) {
    @apply text-xs;
}
div.widget-properties-editor :deep(.tags-input-item-delete .icon-small) {
    @apply h-3 w-3;
}

h2 {
    @apply font-bold my-2;
}
h4 {
    @apply mb-0 mt-4 text-base;
}
</style>
